import React from "react";
import api from "../../../lib/api";
import {Button} from "@armus/armus-dashboard";

const CustomerReport = (props) => {
    const {
        orgKey,
        streamOrgKey,
        streamName,
        streamFileId
    } = props;
    const [content, setContent] = React.useState(null);

    React.useEffect(() => {
        api.loadStreamFileReport(
            orgKey,
            streamOrgKey,
            streamName,
            streamFileId
        ).then((data) => {
            setContent(data);
        }, (err) => {
            console.log("Error loading content", err);
        });
    }, [orgKey, streamOrgKey, streamName, streamFileId]);

    const handleDownload = (e) => {
            api.loadStreamFileReport(
                orgKey,
                streamOrgKey,
                streamName,
                streamFileId
            ).then((data) => {
                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
                element.setAttribute('download', 'customer-report.txt');

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            }, (err) => {
                console.log("Error loading content", err);
            });
    };
    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '3px'
    };

    return (
        <div>
            <div style={containerStyle}>
            <div>
                <strong>Customer Report:</strong>
            </div>
            <Button
                style={{float: "right"}}
                color="primary-alt"
                onClick={handleDownload}>Download Customer Report</Button>
            </div>
            <div style={{
                whiteSpace: "pre",
                maxHeight: 400,
                overflow: "auto",
                border: "1px solid #ddd",
                padding: 10
            }}>{content ? content : "Loading..."}</div>
        </div>
    );
};

export default CustomerReport;
